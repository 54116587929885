import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@app/services/user.service';
import * as moment from 'moment';
declare var $: any;

@Injectable()
export class NightModeService {
  public setting;
  public sunrise;
  public sunset;

  constructor(public http: HttpClient, public userService: UserService) {
    // this.init();

    this.setting = this.isNightTime() ? 'auto_night' : 'auto_day';

    if (localStorage.getItem('night_mode_setting')) {
      this.setting = localStorage.getItem('night_mode_setting');
    }
  }

  init() {
    this.pullDayNightCycle();
    setInterval(() => this.update(), 1 * 1000);
    setInterval(() => this.pullDayNightCycle(), 60 * 60 * 1000);
  }

  isNightTime() {
    const now = new Date();
    const hours = now.getHours();

    if (!this.sunset || !this.sunrise) {
      return hours < 6 || hours > 19;
    }

    return moment().isSameOrBefore(this.sunrise) || moment().isSameOrAfter(this.sunset);
  }

  pullDayNightCycle() {
    this.http.get<any>('https://api.sunrise-sunset.org/json?lat=52.275001&lng=5.523589&formatted=0').subscribe(res => {
      this.sunset = res.results.sunset;
      this.sunrise = res.results.sunrise;
    });
  }

  toggleNightMode() {
    if (this.isAutomatic()) {
      this.setting = this.isNightTime() ? 'day' : 'night';
    } else {
      if (this.isNightTime() && this.setting === 'day') {
        this.setting = 'night';
      } else if (!this.isNightTime() && this.setting === 'night') {
        this.setting = 'day';
      } else {
        this.setting = this.isNightTime() ? 'auto_night' : 'auto_day';
      }
    }

    if (this.isAutomatic()) {
      localStorage.removeItem('night_mode_setting');
    } else {
      localStorage.setItem('night_mode_setting', this.setting);
    }

    this.update();
    this.saveSetting();
  }

  saveSetting() {
    const _id = JSON.parse(localStorage.getItem('user'))._id;
    this.userService.getById(_id).subscribe(user => {
      if (this.isAutomatic()) {
        user.night_mode_setting = 'auto';
      } else {
        user.night_mode_setting = this.setting;
      }

      this.userService.update(user);
    });
  }

  isAutomatic() {
    return this.setting === 'auto_day' || this.setting === 'auto_night';
  }

  isNight() {
    return this.setting === 'auto_night' || this.setting === 'night';
  }

  update() {
    if (this.setting !== localStorage.getItem('night_mode_setting')) {
      this.setting = localStorage.getItem('night_mode_setting') || 'auto_day';
      this.setting = this.getMode();
    }

    // if (this.isNight()) {
    //   $('body').addClass('night-mode');
    // } else {
    //   $('body').removeClass('night-mode');
    // }
  }

  getMode() {
    if (this.isAutomatic()) {
      if (this.isNightTime()) {
        return 'auto_night';
      } else {
        return 'auto_day';
      }
    } else {
      return this.setting;
    }
  }
}
